.story-photo {
    display: block;
    position: relative;
    height: 100vh;
    width: 100vw;
}

.story-photo_photo {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.story-photo_header {
    display: block;
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    padding: 15px;
    text-shadow: #000000 0 0 3px;
}

.story-photo_header_title {
    font-size: 16px;
}

.story-photo_header_subtitle {
    font-size: 10px;
}
