.story-photos {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
}

.story-photos--loading > div.spinner-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
}

.story-photos > div {
    height: 100vh;
    width: 100vw;
}

.story-photos_slideshow {
    width: 100%;
    height: 100%;
}

.story-photos_empty {
    text-transform: uppercase;
}
